import React, { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ActionIcon, Modal, Loader, NativeSelect } from '@mantine/core';
import { FilePond, registerPlugin } from 'react-filepond';
import { Trash } from 'tabler-icons-react';
import { useForm as manTineForm, formList } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import { FirebaseContext } from '../Firebase';
import { navigate } from 'gatsby';

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginImageCrop,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginImageEdit
);

const HIM = () => {
  // useEffect(() => {
  //   firebase.saveReport().then(() => {
  //     console.log('successfully ran');
  //   });
  // }, []);

  const { register, reset, handleSubmit, errors } = useForm();
  const [files, setFiles] = useState([]);
  const [appNoSt, setAppNo] = useState('');
  const [passport, setPassport] = useState([]);
  const [submittedData, setSubmittedData] = useState({});
  const [local, setLocal] = useState({});
  const [opened, setOpened] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);
  let docURLs = [];
  let picURL = [];
  const { firebase } = useContext(FirebaseContext);

  useEffect(() => {
    const test = localStorage.getItem('local');
    const parsed = JSON.parse(test);
    setLocal({ ...parsed });
  }, []);

  const uploadDocsPromise = (docFile, userId) => {
    const path = `registrations/${userId}/docs/`;
    return new Promise((resolve, reject) => {
      if (!docFile) return reject('No document to upload');
      firebase
        .storeFiles({ path: path + docFile.file.name, docFile: docFile.file })
        .then((urls) => {
          docURLs.push({
            url: urls,
            name: docFile.file.name,
          });
          resolve(urls);
        })
        .catch(() => {
          setLoading(false);
          setErrorModal(true);
          return reject;
        });
    });
  };

  const uploadPicPromise = (docFile, userId) => {
    const path = `registrations/${userId}/pic/`;
    return new Promise((resolve, reject) => {
      if (!docFile) return reject('No image to upload');
      firebase
        .storeFiles({ path: path + docFile.file.name, docFile: docFile.file })
        .then((urls) => {
          picURL.push(urls);
          resolve(urls);
        })
        .catch(() => {
          setLoading(false);
          setErrorModal(true);
          return reject;
        });
    });
  };

  const form = manTineForm({
    initialValues: {
      education: formList([]),
    },
  });

  const onSubmit = (raw) => {
    setSubmittedData(raw);
    setLoading(true);
    raw.DateSubmitted = Date.now();
    const localhere = localStorage.getItem('local');
    const parsedLocal = JSON.parse(localhere);
    raw.course = parsedLocal.course;
    raw.flag = 'Submitted';

    if (files.length !== 0 && passport.length !== 0) {
      Promise.all(
        files.map((item) => uploadDocsPromise(item, parsedLocal.userId))
      )
        .then(() => {
          Promise.all(
            passport.map((item) => uploadPicPromise(item, parsedLocal.userId))
          )
            .then(() => {
              raw.documents = docURLs;
              raw.uid = parsedLocal.userId;
              raw.passport = picURL[0];
              const data = { ...raw, ...form.values };

              firebase
                .applyCourse({ data, id: parsedLocal.userId })
                .then((data) => {
                  if (data === 'no doc') {
                    setLoading(false);
                    setErrorModal(true);
                  } else {
                    setLoading(false);
                    setAppNo(data);
                    setOpened(true);
                    reset({ ...submittedData });
                  }
                });
            })
            .catch(() => {
              setLoading(false);
              setErrorModal(true);
            });
        })
        .catch(() => {
          setLoading(false);
          setErrorModal(true);
        });
    } else {
    setLoading(false);
      showNotification({
        title: 'Error',
        color: 'red',
        message:
          'Select supporting documents to upload and passport photograph, before submitting',
      });
    }
  };

  const eduFields = form.values.education.map((_, index) => (
    <div className='row rowStyle' key={index}>
      <div className='col-lg-3'>
        <span className='form-span'>O Level Type</span>
        <NativeSelect
          name={`type${index}`}
          required
          {...form.getListInputProps('education', index, 'type')}
          data={[
            {
              value: 'SSCE',
              label: 'SSCE',
            },
            {
              value: 'NECO',
              label: 'NECO',
            },
          ]}
        />
      </div>
      <div className='col-lg-4'>
        <span className='form-span'>Subject</span>
        <input
          type='text'
          name={`subject${index}`}
          required
          {...form.getListInputProps('education', index, 'subject')}
        />
      </div>
      <div className='col-lg-1 offset-lg-2'>
        <ActionIcon
          color='red'
          variant='hover'
          onClick={() => form.removeListItem('education', index)}
        >
          <Trash size={16} />
        </ActionIcon>
      </div>
    </div>
  ));

  const defaultEducation = {
    grade: '',
    type: '',
    subject: '',
  };

  return (
    <>
      <h2 className='blog-one__title'>
        Application to the School of {local.course} program
      </h2>
      <span>Please Fill in Capital Letters</span>
      <form
        className='contact-one__form contact-form-validated contact-one'
        style={{ padding: 0 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='row low-gutters'>
          <div className='col-lg-4'>
            <span className='form-span'>First Name</span>
            <input
              type='text'
              name='firstName'
              ref={register({ required: true })}
            />
            {errors.firstName && (
              <span className='errorText'>
                <i className='fas fa-exclamation-triangle'></i> This field is
                required
              </span>
            )}
          </div>
          <div className='col-lg-4'>
            <span className='form-span'>Middle Name</span>
            <input type='text' name='middleName' ref={register()} />
          </div>
          <div className='col-lg-4'>
            <span className='form-span'>Surname</span>
            <input
              type='text'
              name='lastName'
              ref={register({ required: true })}
            />
            {errors.lastName && (
              <span className='errorText'>
                <i className='fas fa-exclamation-triangle'></i> This field is
                required
              </span>
            )}
          </div>
          <div className='col-lg-4'>
            <span className='form-span'>Gender</span>
            <NativeSelect
              name='gender'
              ref={register({ required: true })}
              data={[
                {
                  value: 'Male',
                  label: 'Male',
                },
                {
                  value: 'Female',
                  label: 'Female',
                },
              ]}
            />
          </div>
          <div className='col-lg-4'>
            <span className='form-span'>Date of Birth</span>
            <input type='date' name='dob' ref={register({ required: true })} />
            {errors.dob && (
              <span className='errorText'>
                <i className='fas fa-exclamation-triangle'></i> This field is
                required
              </span>
            )}
          </div>
          <div className='col-lg-4'>
            <span className='form-span'>Nationality</span>
            <input
              type='text'
              name='nationality'
              ref={register({ required: true })}
            />
            {errors.nationality && (
              <span className='errorText'>
                <i className='fas fa-exclamation-triangle'></i> This field is
                required
              </span>
            )}
          </div>
          <div className='col-lg-4'>
            <span className='form-span'>State of Origin</span>
            <input
              type='text'
              name='state'
              ref={register({ required: true })}
            />
            {errors.state && (
              <span className='errorText'>
                <i className='fas fa-exclamation-triangle'></i> This field is
                required
              </span>
            )}
          </div>
          <div className='col-lg-4'>
            <span className='form-span'>Email</span>
            <input
              type='email'
              name='email'
              ref={register({ required: true })}
            />
            {errors.email && (
              <span className='errorText'>
                <i className='fas fa-exclamation-triangle'></i> This field is
                required
              </span>
            )}
          </div>
          <div className='col-lg-4'>
            <span className='form-span'>Phone Number</span>
            <input
              type='text'
              name='phone'
              ref={register({ required: true })}
            />
          </div>
          {local.course && !local.course.includes('HND') && (
            <div className='col-lg-4'>
              <span className='form-span'>JAMB Result/Score</span>
              <input
                type='text'
                name='jamb'
                ref={register({ required: true })}
              />
            </div>
          )}
          <div className='col-lg-12'>
            <h3 className='form-span'>Previous Institution Details</h3>
          </div>
          <div className='col-lg-4'>
            <span className='form-span'>Previous Institution Attended</span>
            {local.course && local.course.includes('HND') ? (
              <NativeSelect
                name='previousIns'
                ref={register({ required: true })}
                data={[
                  {
                    value: 'Secondary',
                    label: 'Secondary',
                  },
                  {
                    value: 'Others',
                    label: 'Others',
                  },
                  {
                    value: 'Higher Institution',
                    label: 'Higher Institution',
                  },
                ]}
              />
            ) : (
              <NativeSelect
                name='previousIns'
                ref={register({ required: true })}
                data={[
                  {
                    value: 'Secondary',
                    label: 'Secondary',
                  },
                  {
                    value: 'Others',
                    label: 'Others',
                  },
                ]}
              />
            )}
            {errors.previousIns && (
              <span className='errorText'>
                <i className='fas fa-exclamation-triangle'></i> This field is
                required
              </span>
            )}
          </div>
          <div className='col-lg-4'>
            <span className='form-span'>Certificate Obtained</span>
            <input
              type='text'
              name='previousInsCert'
              ref={register({ required: true })}
            />
            {errors.previousInsCert && (
              <span className='errorText'>
                <i className='fas fa-exclamation-triangle'></i> This field is
                required
              </span>
            )}
          </div>
          <div className='col-lg-4'>
            <span className='form-span'>Grade</span>
            <input
              type='text'
              name='previousInsgrade'
              ref={register({ required: true })}
            />
            {errors.previousInsgrade && (
              <span className='errorText'>
                <i className='fas fa-exclamation-triangle'></i> This field is
                required
              </span>
            )}
          </div>
          <div className='col-lg-12'>
            <h3 className='form-span'>O Level Details</h3>
            {eduFields}
            <br />

            <button
              type='button'
              onClick={() => {
                const currentEduCount = form.values.education.length;
                const maxAllowed = 2; // 1 extra + existing
                if (currentEduCount < maxAllowed) {
                  form.addListItem('education', defaultEducation);
                }
              }}
              className='thm-btn2'
              disabled={form.values.education.length >= 2} // Disable if max limit reached
            >
              Add New O Level Result
            </button>
          </div>
          <div className='col-lg-12'>
            <h3 className='form-span'>Jamb Section</h3>
            <div className='col-lg-12'>
              <span className='form-span'>Jamb Registration Number</span>
              <input
                type='text'
                name='registrationNumber'
                ref={register({ required: true })}
              />
            </div>
            <div className='col-lg-12'>
              <span className='form-span'>Year of Jamb Examination</span>
              <input
                type='text'
                name='yearOfExamination'
                ref={register({ required: true })}
              />
            </div>
            <div className='col-lg-12'>
              <span className='form-span'>Jamb Examination Number</span>
              <input
                type='text'
                name='examinationNumber'
                ref={register({ required: true })}
              />
            </div>
            <div className='col-lg-12'>
              <span className='form-span'>Jamb Examination Score</span>
              <input
                type='number'
                name='examinationScore'
                ref={register({ required: true, min: 150 })}
                placeholder='Minumun of 150'
              />
            </div>
            </div>
          <div className='col-lg-3'>
            <span className='form-span'>Photograph</span>
            <FilePond
              files={passport}
              maxFileSize='1MB'
              acceptedFileTypes={['image/*']}
              credits={false}
              onupdatefiles={(fileItems) => {
                setPassport(fileItems);
                fileItems.map((rawData) => {
                  return Object.assign(rawData.file, {
                    blob: URL.createObjectURL(rawData.file),
                  });
                });
              }}
              allowMultiple={false}
              instantUpload={false}
              name='passport'
              labelIdle='Drag & Drop your passport photograph or <span class="filepond--label-action">Browse</span>'
              imagePreviewHeight={170}
              imageCropAspectRatio='1:1'
              imageResizeTargetWidth={200}
              imageResizeTargetHeight={200}
              stylePanelLayout='compact circle'
              styleLoadIndicatorPosition='center bottom'
              styleProgressIndicatorPosition='right bottom'
              styleButtonRemoveItemPosition='left bottom'
              styleButtonProcessItemPosition='right bottom'
            />
          </div>
          <div className='col-lg-8'>
            <h4 className='form-span'>Upload Required Documents</h4>
            <h3 className='sidebar__title'>
              Note: All listed documents below must be uploaded for your
              admission to be considered
            </h3>
            <div className='row'>
              <div className='col-lg-6'>
                <p style={{ color: '#f16101' }}>
                  Original SSCE/GCE/NECO Certificate
                </p>
              </div>
              <div className='col-lg-6'>
                <p style={{ color: '#f16101' }}>JAMB Admission letter</p>
              </div>
              {local.course && local.course.includes('HND') && (
                <>
                  <div className='col-lg-6'>
                    <p style={{ color: '#f16101' }}>
                      National Diploma Certificate
                    </p>
                  </div>
                  <div className='col-lg-6'>
                    <p style={{ color: '#f16101' }}>
                      Evidence of completion of 1 year Industrial/Clinical
                      Attachment from a reputable health institution
                    </p>
                  </div>
                </>
              )}
            </div>
            <FilePond
              files={files}
              maxFileSize='1MB'
              // acceptedFileTypes={[
              //   'image/jpeg, image/png',
              // ]}
              credits={false}
              onupdatefiles={(fileItems) => {
                setFiles(fileItems);
                fileItems.map((rawData) => {
                  return Object.assign(rawData.file, {
                    blob: URL.createObjectURL(rawData.file),
                  });
                });
              }}
              allowMultiple={true}
              maxFiles={7}
              instantUpload={false}
              name='files'
              labelIdle='Drag & Drop your supporting documents or <span class="filepond--label-action">Browse</span>'
            />
          </div>
          <div className='col-lg-12'>
            {loading ? (
              <Loader size='xl' color='orange' variant='dots' />
            ) : (
              <button className='reply-form__btn thm-btn' type='submit'>
                Submit
              </button>
            )}
          </div>
          <Modal
            opened={errorModal}
            onClose={() => setErrorModal(false)}
            title='Error!'
          >
            An error occurred, while saving details, please try again
          </Modal>
          <Modal
            opened={opened}
            onClose={() => setOpened(false)}
            title='Success'
          >
            <p className='about-two__text' style={{ textAlign: 'center' }}>
              Your application has been successfully submitted with Application
              Number: <strong>{appNoSt}</strong>.
              <br />
              <br />
              <button
                onClick={() => navigate('/')}
                type='button'
                className='contact-one__btn thm-btn'
              >
                Ok
              </button>
            </p>
          </Modal>
        </div>
      </form>
    </>
  );
};

export default HIM;
