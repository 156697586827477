import React, { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ActionIcon, Modal, Loader, NativeSelect } from '@mantine/core';
import { FilePond, registerPlugin } from 'react-filepond';
import { Trash } from 'tabler-icons-react';
import { showNotification } from '@mantine/notifications';
import { useForm as manTineForm, formList } from '@mantine/form';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { FirebaseContext } from '../Firebase';
import { navigate } from 'gatsby';

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

const Ana = () => {
  const { register, reset, handleSubmit, errors } = useForm();
  const [files, setFiles] = useState([]);
  const [passport, setPassport] = useState([]);
  const [appNoSt, setAppNo] = useState('');
  const [submittedData, setSubmittedData] = useState({});
  const [local, setLocal] = useState({});
  const [opened, setOpened] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);
  let docURLs = [];
  let picURL = [];
  const { firebase, user } = useContext(FirebaseContext);

  useEffect(() => {
    const test = localStorage.getItem('local');
    const parsed = JSON.parse(test);
    setLocal({ ...parsed });
  }, []);

  const uploadDocsPromise = (docFile, userId) => {
    const path = `registrations/${userId}/docs/`;
    return new Promise((resolve, reject) => {
      if (!docFile) return reject('No document to upload');
      firebase
        .storeFiles({ path: path + docFile.file.name, docFile: docFile.file })
        .then((urls) => {
          docURLs.push({
            url: urls,
            name: docFile.file.name,
          });  
          resolve(urls);
        });
    });
  };

  const uploadPicPromise = (docFile, userId) => {
    const path = `registrations/${userId}/pic/`;
    return new Promise((resolve, reject) => {
      if (!docFile) return reject('No image to upload');
      firebase
        .storeFiles({ path: path + docFile.file.name, docFile: docFile.file })
        .then((urls) => {
          picURL.push(urls);
          resolve(urls);
        })
        .catch(() => {
          setLoading(false);
          setErrorModal(true);
          return reject;
        });
    });
  };

  const form = manTineForm({
    initialValues: {
      subjects: formList([]),
      education: formList([]),
    },
  });

  const onSubmit = (raw) => {

    // setSubmittedData(raw);
    setLoading(true);
    raw.documents = docURLs;
    raw.DateSubmitted = Date.now();
    const authUser = localStorage.getItem('authUser');
    const localhere = localStorage.getItem('local');
    const parsedUser = JSON.parse(authUser);
    const parsedLocal = JSON.parse(localhere);
    raw.passport = picURL;
    raw.flag = 'Submitted';
    raw.course = parsedLocal.course;

    if (files.length !== 0 || passport.length !== 0) {
      Promise.all(
        files.map((item) => uploadDocsPromise(item, parsedLocal.userId))
      )
        .then(() => {
          Promise.all(
            passport.map((item) => uploadPicPromise(item, parsedLocal.userId))
          )
            .then(() => {
              raw.documents = docURLs;
              raw.uid = parsedLocal.userId;
              raw.passport = picURL[0];
              const data = { ...raw, ...form.values };

              firebase
                .applyCourse({ data, id: parsedLocal.userId })
                .then(() => {
                  setLoading(false);
                  setOpened(true);
                  reset({ ...submittedData });
                });
            })
            .catch(() => {
              setLoading(false);
              setErrorModal(true);
            });
        })
        .catch(() => {
          setLoading(false);
          setErrorModal(true);
        });
    } else {
      showNotification({
        title: 'Error',
        color: 'red',
        message:
          'Select supporting documents to upload and passport photograph, before submitting',
      });
    }
  };

  const subFields = form.values.subjects.map((_, index) => (
    <div className='row rowStyle' key={index}>
      <div className='row'>
        <div className='col-lg-6'>
          <span className='form-span'>Exam Name, Date, Exam No:</span>
          <input
            type='text'
            name={`examDetails${index}`}
            {...form.getListInputProps('subjects', index, 'details')}
          />
        </div>
        <div className='col-lg-3'>
          <span className='form-span'>Subject 1:</span>
          <input
            type='text'
            name={`subject1${index}`}
            {...form.getListInputProps('subjects', index, 'sub1')}
          />
        </div>
        <div className='col-lg-3'>
          <span className='form-span'>Grade:</span>
          <input
            type='text'
            name={`grade1${index}`}
            {...form.getListInputProps('subjects', index, 'grade1')}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-5 offset-lg-1'>
          <span className='form-span'>Subject 2:</span>
          <input
            type='text'
            name={`subject2${index}`}
            {...form.getListInputProps('subjects', index, 'sub2')}
          />
        </div>
        <div className='col-lg-5 offset-lg-1'>
          <span className='form-span'>Grade:</span>
          <input
            type='text'
            name={`grade2${index}`}
            {...form.getListInputProps('subjects', index, 'grade2')}
          />
        </div>
      </div>
      <div className='col-lg-12'></div>
      <div className='row'>
        <div className='col-lg-5 offset-lg-1'>
          <span className='form-span'>Subject 3:</span>
          <input
            type='text'
            name={`subject3${index}`}
            {...form.getListInputProps('subjects', index, 'sub3')}
          />
        </div>
        <div className='col-lg-5 offset-lg-1'>
          <span className='form-span'>Grade:</span>
          <input
            type='text'
            name={`grade3${index}`}
            {...form.getListInputProps('subjects', index, 'grade3')}
          />
        </div>
      </div>
      <div className='col-lg-12'></div>

      <div className='row'>
        <div className='col-lg-5 offset-lg-1'>
          <span className='form-span'>Subject 4:</span>
          <input
            type='text'
            name={`subject4${index}`}
            {...form.getListInputProps('subjects', index, 'sub4')}
          />
        </div>
        <div className='col-lg-5 offset-lg-1'>
          <span className='form-span'>Grade:</span>
          <input
            type='text'
            name={`grade4${index}`}
            {...form.getListInputProps('subjects', index, 'grade4')}
          />
        </div>
      </div>
      <div className='col-lg-12'></div>

      <div className='row'>
        <div className='col-lg-5'>
          <span className='form-span'>Subject 5:</span>
          <input
            type='text'
            name={`subject5${index}`}
            {...form.getListInputProps('subjects', index, 'sub5')}
          />
        </div>
        <div className='col-lg-5'>
          <span className='form-span'>Grade:</span>
          <input
            type='text'
            name={`grade5${index}`}
            {...form.getListInputProps('subjects', index, 'grade5')}
          />
        </div>
        <div className='col-lg-1'>
          <ActionIcon
            color='red'
            variant='hover'
            onClick={() => form.removeListItem('subjects', index)}
          >
            <Trash size={16} />
          </ActionIcon>
        </div>
      </div>
    </div>
  ));

  const eduFields = form.values.education.map((_, index) => (
    <div className='row rowStyle' key={index}>
      <div className='col-lg-4'>
        <span className='form-span'>Type of Institution</span>
        <NativeSelect
          name={`type${index}`}
          required
          {...form.getListInputProps('education', index, 'type')}
          data={[
            {
              value: 'Primary',
              label: 'Primary',
            },
            {
              value: 'Secondary',
              label: 'Secondary',
            },
            {
              value: 'Others (Specify)',
              label: 'Others (Specify)',
            },
          ]}
        />
      </div>
      <div className='col-lg-2'>
        <span className='form-span'>From</span>
        <input
          type='text'
          name={`eduCommenced${index}`}
          required
          {...form.getListInputProps('education', index, 'from')}
        />
      </div>
      <div className='col-lg-2'>
        <span className='form-span'>To</span>
        <input
          type='text'
          {...form.getListInputProps('education', index, 'to')}
          name={`eduLeft${index}`}
          required
        />
      </div>
      <div className='col-lg-1'>
        <ActionIcon
          color='red'
          variant='hover'
          onClick={() => form.removeListItem('education', index)}
        >
          <Trash size={16} />
        </ActionIcon>
      </div>
    </div>
  ));

  const defaultEducation = {
    type: '',
    from: '',
    to: '',
  };

  const defaultSubject = {
    details: '',
    sub1: '',
    grade1: '',
    sub2: '',
    grade2: '',
    sub3: '',
    grade3: '',
    sub4: '',
    grade4: '',
    sub5: '',
    grade5: '',
  };

  return (
    <>
      <h2 className='blog-one__title'>Apply to the {local.course} program</h2>
      <form
        className='contact-one__form contact-form-validated contact-one'
        style={{ padding: 0 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='row low-gutters'>
          <div className='col-lg-4'>
            <span className='form-span'>First Name</span>
            <input
              type='text'
              name='firstName'
              ref={register({ required: true })}
            />
            {errors.firstName && (
              <span className='errorText'>
                <i className='fas fa-exclamation-triangle'></i> This field is
                required
              </span>
            )}
          </div>
          <div className='col-lg-4'>
            <span className='form-span'>Middle Name</span>
            <input type='text' name='middleName' ref={register()} />
          </div>
          <div className='col-lg-4'>
            <span className='form-span'>Surname</span>
            <input
              type='text'
              name='lastName'
              ref={register({ required: true })}
            />
            {errors.lastName && (
              <span className='errorText'>
                <i className='fas fa-exclamation-triangle'></i> This field is
                required
              </span>
            )}
          </div>
          <div className='col-lg-4'>
            <span className='form-span'>Gender</span>
            <NativeSelect
              name='gender'
              ref={register({ required: true })}
              data={[
                {
                  value: 'Male',
                  label: 'Male',
                },
                {
                  value: 'Female',
                  label: 'Female',
                },
              ]}
            />
          </div>
          <div className='col-lg-4'>
            <span className='form-span'>Date of Birth</span>
            <input type='date' name='dob' ref={register({ required: true })} />
            {errors.dob && (
              <span className='errorText'>
                <i className='fas fa-exclamation-triangle'></i> This field is
                required
              </span>
            )}
          </div>
          <div className='col-lg-4'>
            <span className='form-span'>Email</span>
            <input
              type='email'
              name='email'
              ref={register({ required: true })}
            />
            {errors.email && (
              <span className='errorText'>
                <i className='fas fa-exclamation-triangle'></i> This field is
                required
              </span>
            )}
          </div>
          <div className='col-lg-4'>
            <span className='form-span'>Phone Number</span>
            <input
              type='text'
              name='phone'
              ref={register({ required: true })}
            />
            {errors.phone && (
              <span className='errorText'>
                <i className='fas fa-exclamation-triangle'></i> This field is
                required
              </span>
            )}
          </div>
          <div className='col-lg-4'>
            <span className='form-span'>Marital Status</span>
            <NativeSelect
              name='maritalStatus'
              ref={register({ required: true })}
              data={[
                {
                  value: 'Married',
                  label: 'Married',
                },
                {
                  value: 'Single',
                  label: 'Single',
                },
              ]}
            />
            {errors.maritalStatus && (
              <span className='errorText'>
                <i className='fas fa-exclamation-triangle'></i> This field is
                required
              </span>
            )}
          </div>
          <div className='col-lg-4'>
            <span className='form-span'>Maiden Name</span>
            <input type='text' name='maidenName' ref={register()} />
          </div>
          <div className='col-lg-4'>
            <span className='form-span'>State of Origin</span>
            <input type='text' name='state' ref={register()} />
          </div>
          <div className='col-lg-4'>
            <span className='form-span'>Local Government</span>
            <input type='text' name='localGov' ref={register()} />
          </div>
          <div className='col-lg-4'>
            <span className='form-span'>Nationality</span>
            <input
              type='text'
              name='nationality'
              ref={register({ required: true })}
            />
            {errors.nationality && (
              <span className='errorText'>
                <i className='fas fa-exclamation-triangle'></i> This field is
                required
              </span>
            )}
          </div>
          <div className='col-lg-4'>
            <span className='form-span'>Religion</span>
            <input type='text' name='town' ref={register()} />
          </div>
          <div className='col-lg-4'>
            <span className='form-span'>Permanent Home Address</span>
            <textarea ref={register()} name='address'></textarea>
          </div>
          <div className='col-lg-4'>
            <span className='form-span'>Next of Kin</span>
            <input type='text' name='kin' ref={register({ required: true })} />
            {errors.kin && (
              <span className='errorText'>
                <i className='fas fa-exclamation-triangle'></i> This field is
                required
              </span>
            )}
          </div>
          <div className='col-lg-4'>
            <span className='form-span'>Address of Next of Kin</span>
            <textarea ref={register()} name='nokAddress'></textarea>
          </div>
          <div className='col-lg-4'>
            <span className='form-span'>Phone Number of Next of Kin</span>
            <input
              type='text'
              name='nokPhone'
              ref={register({ required: true })}
            />
            {errors.nokPhone && (
              <span className='errorText'>
                <i className='fas fa-exclamation-triangle'></i> This field is
                required
              </span>
            )}
          </div>
          {/* <div className='col-lg-12'>
            <span className='form-span'>Institutions Attended</span>
            {eduFields}
            <br />

            <button
              type='button'
              onClick={() => form.addListItem('education', defaultEducation)}
              className='thm-btn2'
            >
              Add New Education
            </button>
          </div>
          <div className='col-lg-12'>
            <span className='form-span'>
              Examinations Passed Or Entered For (WAEC or NECO)
            </span>
            {subFields}
            <br />
            <button
              type='button'
              onClick={() => form.addListItem('subjects', defaultSubject)}
              className='thm-btn2'
            >
              Add new Exam
            </button>
          </div>                     */}
          <div className='col-lg-3'>
            <span className='form-span'>Passport Photograph</span>
            <FilePond
              files={passport}
              maxFileSize='1MB'
              acceptedFileTypes={['image/*']}
              credits={false}
              onupdatefiles={(fileItems) => {
                console.log('fileitems', fileItems);
                setPassport(fileItems);
                fileItems.map((rawData) => {
                  return Object.assign(rawData.file, {
                    blob: URL.createObjectURL(rawData.file),
                  });
                });
              }}
              allowMultiple={false}
              instantUpload={false}
              name='passport'
              labelIdle='Drag & Drop your passport photograph or <span class="filepond--label-action">Browse</span>'
              imagePreviewHeight={170}
              imageCropAspectRatio='1:1'
              imageResizeTargetWidth={200}
              imageResizeTargetHeight={200}
              stylePanelLayout='compact circle'
              styleLoadIndicatorPosition='center bottom'
              styleProgressIndicatorPosition='right bottom'
              styleButtonRemoveItemPosition='left bottom'
              styleButtonProcessItemPosition='right bottom'
            />
          </div>
          <div className='col-lg-12'>
            <h4 className='form-span'>Upload Required Documents</h4>
            <h3 className='sidebar__title'>
              Note: All listed documents below must be uploaded for your
              admission to be considered
            </h3>
            <div className='row'>
              <div className='col-lg-6'>
                <p style={{ color: '#f16101' }}>
                  Photocopy of school Certificate
                </p>
              </div>
              <div className='col-lg-6'>
                <p style={{ color: '#f16101' }}>Birth Certificate</p>
              </div>
            </div>
            <FilePond
              files={files}
              maxFileSize='1MB'
              // acceptedFileTypes={[
              //   'image/jpeg, image/png',
              // ]}
              credits={false}
              onupdatefiles={(fileItems) => {
                setFiles(fileItems);
                fileItems.map((rawData) => {
                  return Object.assign(rawData.file, {
                    blob: URL.createObjectURL(rawData.file),
                  });
                });
              }}
              allowMultiple={true}
              maxFiles={3}
              instantUpload={false}
              name='files'
              labelIdle='Drag & Drop your supporting documents or <span class="filepond--label-action">Browse</span>'
            />
          </div>
          <div className='col-lg-12'>
            {loading ? (
              <Loader size='xl' color='orange' variant='dots' />
            ) : (
              <button className='reply-form__btn thm-btn' type='submit'>
                Submit
              </button>
            )}
          </div>
          <Modal
            opened={errorModal}
            onClose={() => setErrorModal(false)}
            title='Error!'
          >
            An error occurred, while saving details, please try again
          </Modal>
          <Modal
            opened={opened}
            onClose={() => {
              // navigate('/');
              setOpened(false);
            }}
            title='Success'
          >
            <p className='about-two__text' style={{ textAlign: 'center' }}>
              Your application has been successfully submitted with Application
              Number <strong>{appNoSt}</strong>
              <br />
              <br />
              <button
                onClick={() => navigate('/')}
                type='button'
                className='contact-one__btn thm-btn'
              >
                Ok
              </button>
            </p>
          </Modal>
        </div>
      </form>
    </>
  );
};

export default Ana;
