import React from 'react';
import Layout from "../components/Layout";
import NavThree from "../components/NavThree";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import Profile from "../components/Profile";

const ProfilePage = () => {
    return (
        <Layout pageTitle="Student Profile | LUTH Schools">
            <NavThree />
            <PageHeader title="" />
            <Profile />
            <Footer />
        </Layout>
    );
};

export default ProfilePage;
