import React, { useContext, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { FirebaseContext } from './Firebase';
import { Loader, Modal } from '@mantine/core';

const UpdatePassword = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [succModal, setSuccModal] = useState(false);
  const password = useRef({});
  const {
    register,
    handleSubmit,
    errors,
    setError,
    watch,
    clearErrors,
  } = useForm();
  const { firebase, user } = useContext(FirebaseContext);
  password.current = watch('new', '');

  const onSubmit = (formData) => {
    setIsLoading(true);
    firebase
      .changePassword({
        email: user.email,
        currentPassword: formData.current,
        newPassword: formData.new,
      })
      .then((data) => {
        setIsLoading(false);
        if (typeof data !== 'undefined') {
          if (data.message) {
            const replaced = data.message.replace('Firebase:', '');
            setError('confirm', { message: replaced });
          }
        } else {
          setSuccModal(true);
        }
      })
      .catch(() => {
        setIsLoading(false);
        setError('confirm', { message: 'An error occurred, please try again' });
      });
  };

  const finishUp = () => {
    setSuccModal(false);
    props.func('success');
  };

  return (
    <>
      <h2 className='blog-one__title'>Change Password</h2>
      <form onSubmit={handleSubmit(onSubmit)} className='reply-form'>
        <div className='row'>
          <div className='col-lg-12'>
            <input
              type='text'
              name='current'
              placeholder='Enter current password'
              className='reply-form__field'
              ref={register({ required: true })}
            />
            {errors.current && (
              <span className='errorText'>
                <i className='fas fa-exclamation-triangle'></i> This field is
                required
              </span>
            )}
          </div>
          <div className='col-lg-12'>
            <input
              type='password'
              name='new'
              placeholder='Enter new password'
              className='reply-form__field'
              ref={register({ required: true })}
            />
            {errors.new && (
              <span className='errorText'>
                <i className='fas fa-exclamation-triangle'></i> This field is
                required
              </span>
            )}
          </div>
          <div className='col-lg-12'>
            <input
              type='password'
              name='confirm'
              placeholder='Confirm password'
              className='reply-form__field'
              //   ref={register({ required: true })}
              ref={register({
                validate: (value) =>
                  value === password.current || 'The passwords do not match',
                required: true,
              })}
            />
            {errors.confirm && (
              <span className='errorText'>
                <i className='fas fa-exclamation-triangle'></i>{' '}
                {errors.confirm.message}
              </span>
            )}
          </div>
          <div className='col-lg-12'>
            {isLoading ? (
              <Loader size='xl' color='orange' variant='dots' />
            ) : (
              <button className='reply-form__btn thm-btn' type='submit'>
                Submit
              </button>
            )}
            <Modal onClose={finishUp} opened={succModal}>
              <div className='block-title text-left'>
                <h2
                  className='block-title__title'
                  style={{ textAlign: 'center' }}
                >
                  Success
                </h2>
              </div>
              <p className='about-two__text' style={{ textAlign: 'center' }}>
                Password successfully updated
                <br />
                <br />
                <button onClick={finishUp} className='contact-one__btn thm-btn'>
                  Ok
                </button>
              </p>
            </Modal>
          </div>
        </div>
      </form>
    </>
  );
};

export default UpdatePassword;
